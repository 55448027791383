import React from 'react';

import { observer } from 'mobx-react-lite';

import Register, { IRegisterSubmitData } from '../../../common/Forms/Register';
import storeApp from '../../../../store/AppStore';
import { EAuthModalSteps, EModalNames } from '../../../Modals/Modals.type';
import { ModalView } from '../../../common/Modal';

const RegisterForm = () => {
  const onSubmit = (data: IRegisterSubmitData) => {
    storeApp.authRegistration(data);
  };

  const onClickSwitchingEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.setAuthModalData(EAuthModalSteps.Enter);
  };

  const onClickPersonalData = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.agreementPD.title,
      buttonTitle: 'Закрыть',
      content: storeApp.agreementPD.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  const onClickOwnerContract = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.ownerContract.title,
      buttonTitle: "Закрыть",
      content: storeApp.ownerContract.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };

  const onClickAdvertiserContract = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.advertiserContract.title,
      buttonTitle: "Закрыть",
      content: storeApp.advertiserContract.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };

  const backendErrors = React.useMemo(() => {
    return storeApp.errors;
  }, [storeApp.errorsUpdated]);

  return (
    <Register
      cbSubmitAction={onSubmit}
      cbCancelAction={onClickSwitchingEnter}
      cbAgreeAction={onClickPersonalData}
      cbOfertaAction={process.env.REACT_APP_PERSONAL_AREA === "owner" ? onClickOwnerContract : onClickAdvertiserContract}
      backendErrors={backendErrors}
      onlyCountries={storeApp.onlyCountries}
      phoneMasks={storeApp.phoneMasks}
      recaptcha={ storeApp.recaptcha }
      recaptcha2={storeApp.recaptcha.key2}
      isShowRecaptcha={storeApp.recaptcha.isShowRecaptchaSignup}
      cbRecaptchaToken={(token) => storeApp.setRecaptcha2Token(token, 'signup')}
      key={new Date().getTime()}
    />
  );
};

export default observer(RegisterForm);
